<template>
  <div class="help">
    <h3 class="zq-page-title">Help</h3>

    <div class="content">

    </div>
  </div>
</template>

<script>
import routerBreadcrumbs from "@/router/breadcrumb/routerBreadcrumbs";

export default {
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      routerBreadcrumbs(this.$router.currentRoute);
    }
  }
}
</script>